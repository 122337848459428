import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { CookieMeta } from "../data/seo-meta"
import { Link } from "gatsby"
import clsx from "clsx"

const firstPartyCookies = [
  [
    "Marketing email cookie",
    "pum-963",
    "To ask you to subscribe for Slice updates and to keep track if you have already signed up",
    "No",
  ],
  [
    "Identification cookie",
    "_svisitor",
    "To identify if you are the same person on our website and in our application so we can make sure we always have the same information about you",
    "No",
  ],

  [
    "Insurance agent cookie",
    "_s_hs_ref_agent_id",
    "Identifies which insurance agent referred you to Slice",
    "Yes",
  ],
]

const thirdPartyCookies = [
  [
    "Payment processing cookies",
    "Stripe",
    "Allows you to make payments for insurance you purchase ",
    "Yes",
  ],

  [
    "Tracking, monitoring, and analytics cookies",
    "Google, Hubspot, Snowplow, Raygun",
    "To track you for various purposes including identification, analytics and fraud detection",
    "No",
  ],

  [
    "Support cookies",
    "Intercom",
    "Functioning of the online support widget",
    "Yes (for support only)",
  ],

  [
    "Advertising cookies",
    "Google, LinkedIn",
    "To serve you advertisements for Slice",
    "No",
  ],

  [
    "Service monitoring cookies",
    "ShareThis, Twitter",
    "Monitors how various third-party services are used on the website",
    "No",
  ],
]

const browserCookies = [
  {
    name: "Google Chrome",
    link: "https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en",
  },
  {
    name: "Mozilla Firefox",
    link: "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences",
  },
  {
    name: "Microsoft Edge",
    link: "https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy",
  },
  {
    name: "Microsoft Internet Explorer",
    link: "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies",
  },
  {
    name: "Opera",
    link: "https://www.opera.com/help/tutorials/security/privacy/",
  },
  {
    name: "Apple Safari",
    link: "https://support.apple.com/kb/ph21411?locale=en_US",
  },
  {
    name: "iOS",
    link: "https://support.apple.com/en-ca/HT201265",
  },
  {
    name: "Android",
    link: "https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&hl=en",
  },
]

const titleClass = "p1 font-semibold mb-6"
const contentClass = "mb-32"
const linkClass = "underline"

const CookiePolicyPage = () => (
  <Layout>
    <SEO
      description={CookieMeta.description}
      path={CookieMeta.path}
      title={CookieMeta.title}
    />

    <div className="container py-20 md:py-24 p2">
      <h1 className="h1 mb-32">Cookie and Advertising Policy</h1>
      <p className="p1 font-semibold mb-32">(Effective February 1, 2022)</p>
      <h2 className={titleClass}>Hello!</h2>
      <p className={contentClass}>
        Welcome to Slice! We hope you will enjoy and appreciate using our
        Services. This Cookie and Advertising Policy explains how we use cookies
        and other related technologies and tracking technologies to recognise
        you, simplify and enhance your user experience, and facilitate certain
        functions when you use our&nbsp;
        <strong>“Services”</strong>, which may be visiting or using our website
        at&nbsp;
        <Link className={linkClass} to="/">
          https://sliceinsurance.com
        </Link>
        &nbsp; or other websites controlled by us, or downloading and using our
        applications. It also explains how cookies facilitate the use of Slice
        advertising, and how you can opt out of seeing such ads.
      </p>

      <h2 className={titleClass}>About Slice and Contacting Us</h2>
      <p className={contentClass}>
        Slice is actually three different but related companies who are owned by
        the same people - in the U.K. (Slice Labs UK), the U.S. (Slice Insurance
        Technologies Inc.) and Canada (Slice Labs Inc.). All of them are covered
        by this Cookie and Advertising Policy.
        <br />
        <br />
        If you want to ask us anything about what’s in this Cookie and
        Advertising Policy (or anything else privacy-related), you can
        email&nbsp;
        <a
          className={linkClass}
          href="mailto:privacy@sliceinsurance.com"
          rel="noreferrer nofollow"
          target="_blank"
        >
          privacy@sliceinsurance.com
        </a>
        . Here is our mailing address:
        <br />
        <br />
        14 Chamberlain Avenue
        <br />
        Ottawa, Ontario
        <br />
        Canada
        <br />
        K1S 3T3
        <br />
      </p>

      <h2 className={titleClass}>What are Cookies?</h2>
      <p className="mb-8">
        Cookies are small files of alphanumeric text that are placed on your
        computer, tablet or handheld device that allows us to recognise and
        remember you. They are widely used throughout the internet to make
        certain functions work, improve the user experience, and provide certain
        information to website owners. Cookies are sent through your browser and
        stored on your computer’s hard drive or the memory of your tablet or
        mobile device.
        <br />
        <br />
        When you visit or use our Services on the web or in an application, we
        may collect information from you automatically through cookies. Cookies
        are also used to set preferences and remember information about you, so
        when you come back to the website or application you do not have to
        enter certain information again.
        <br />
        <br />
        Cookies can be classified in a variety of ways:
      </p>
      <div className={contentClass}>
        <ul className="list-disc pl-8">
          <li className="mb-8">
            <strong>First-party cookies and third-party cookies.</strong>{" "}
            First-party cookies are cookies set by the website owner, in this
            case Slice. Third-party cookies are set by other companies that are
            hooked into the website to provide certain features like online chat
            support, analytics, or advertising. Third-party cookies will
            remember you when you are on a Slice website but may sometimes
            recognize you when you visit other websites as well.
          </li>
          <li className="mb-8">
            <strong>Session cookies and persistent cookies.</strong> Session
            cookies are only used while you visit a website and will
            automatically be deleted when you leave the website or close your
            browser. Persistent cookies stay on your computer or device after
            you finish at the website, and will either remain until they are
            deleted, or will automatically delete after a set period of time.
          </li>
          <li className="mb-8">
            <strong>Essential and functionality cookies.</strong> Essential
            cookies are necessary for us to provide you with the Slice services
            and sell you insurance or process your claims; functionality cookies
            make your experience better – for example by remembering certain
            information you may have typed in an online form or providing some
            added-on service.
          </li>
          <li className="mb-8">
            <strong>Analytics and performance cookies.</strong> These allow
            Slice to find certain information about you and your computer or
            device, and how you use the website or services, so we can better
            understand how our users use the website and services in the
            aggregate, so we can make improvements for everyone. These may also
            track your IP address, so a website owner can trace you if they have
            to because of some nefarious or fraudulent activity.
          </li>
          <li className="mb-8">
            <strong>Advertising cookies.</strong> Sometimes when you visit a
            website, you will see advertisements for that website or its
            products or services on other websites, and these cookies make that
            happen.
          </li>
        </ul>
      </div>

      <h2 className={titleClass}>Slice First-Party Cookies</h2>
      <p className="mb-8">
        In the table below, we have described the cookies Slice sets on your
        computer or device, the name of the cookie so you can delete it if you
        want, what they do, and if they are essential to providing our services
        to sell you insurance and / or process your claims.
      </p>
      <div className={clsx("table-responsive", contentClass)}>
        <table className="table-fixed divide-y p1 text-left align-top mb-6">
          <thead>
            <tr>
              <th className="w-3/12 th align-top p-6">Cookie</th>
              <th className="w-3/12 th align-top p-6">Cookie name</th>
              <th className="w-3/12 th align-top p-6">Function(s)</th>
              <th className="w-3/12 th align-top p-6">Essential?</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {firstPartyCookies.map((row) => (
              <tr key={row[0]}>
                {row.map((cell) => (
                  <td className="p2 align-top p-6" key={cell}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h2 className={titleClass}>Third-Party Cookies</h2>
      <p className="mb-8">
        In the table below, we have described the types of cookies from
        third-parties used on the Slice websites or applications, what third
        parties they come from, what they do, and if they are essential to
        providing our services.
      </p>
      <div className={clsx("table-responsive", contentClass)}>
        <table className="table-fixed divide-y p1 text-left align-top mb-6">
          <thead>
            <tr>
              <th className="w-3/12 th align-top p-6">Cookies</th>
              <th className="w-3/12 th align-top p-6">Third parties</th>
              <th className="w-3/12 th align-top p-6">Function(s)</th>
              <th className="w-3/12 th align-top p-6">Essential?</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {thirdPartyCookies.map((row) => (
              <tr key={row[0]}>
                {row.map((cell) => (
                  <td className="p2 align-top p-6" key={cell}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h2 className={titleClass}>
        Seeing your Cookies; Deleting, Disabling or Blocking Cookies
      </h2>
      <p className="mb-8">
        You can always see what cookies are on your computer or device and
        delete, disable or block them through your browser or operating system
        settings. You can refer to their help section for instructions, but here
        are instructions for the most commonly-used browsers and operating
        systems:
      </p>
      <ul className="list-disc pl-8">
        {browserCookies.map((browser) => (
          <li key={browser.name}>
            <a
              className={linkClass}
              href={browser.link}
              rel="noreferrer nofollow"
              target="_blank"
            >
              {browser.name}
            </a>
          </li>
        ))}
      </ul>
      <p className={contentClass}>
        Please note that deleting or blocking Slice or third-party cookies may
        reduce your user experience by requiring you to re-enter certain
        information, including information required to use our services.
        Furthermore, deleting certain cookies may prevent certain functions from
        working at all. Deleting essential cookies will prevent you from using
        the Slice Services to buy insurance and / or process your claims.
      </p>

      <h2 className={titleClass}>Slice Advertising and Opting Out</h2>
      <p className={contentClass}>
        Slice currently uses Google AdWords and Display&nbsp;
        <a
          className={linkClass}
          href="https://adwords.google.com/"
          rel="nofollow noreferrer"
          target="_blank"
        >
          Network
        </a>
        &nbsp; and by using our Services you consent to this use. Specifically,
        Slice uses the remarketing features of interest-based advertising of
        Google AdWords that delivers you advertisements that will be of
        particular interest to you, based on your browsing and activity history
        at our websites. These advertisements will appear on third-party
        websites around the web. Google uses specific cookies to allow them to
        serve these ads around the web.
        <br />
        <br />
        You may prevent this type of advertising by deleting the appropriate
        Google cookie through your browser, though this may not be permanent.
        For a more permanent solution, you may opt out of such Google
        advertising by&nbsp;
        <a
          className={linkClass}
          href="https://support.google.com/ads/answer/2662922?hl=en"
          rel="noreferrer nofollow"
          target="_blank"
        >
          following Google’s instructions
        </a>
        .
      </p>

      <h2 className={titleClass}>Server Log Data and Analytics Data</h2>
      <p className={contentClass}>
        When using our Services, we automatically collect certain information
        that is sent from your browser and / or operating system. We may
        automatically collect the following, depending on what Services you are
        using and how you are interacting with the Services: IP address,
        browser, computer or device type, operating system, access times, the
        web page from which you came, and the web page(s) you access. None of
        this information identifies you personally. We use information collected
        in this manner only to better understand your needs and the needs of the
        Services users in the aggregate, and with a view to improving the
        Services generally. Your IP address and other relevant information may
        be used in order to trace any fraudulent or criminal activity, or any
        activity in violation of the Slice Terms of Use.
      </p>

      <h2 className={titleClass}>“Do Not Track” Signals</h2>
      <p className={contentClass}>
        Some browsers have incorporated “Do Not Track” (DNT) features that can
        send a signal to the websites you visit indicating you do not wish to be
        tracked. Because there is not yet a common understanding of how to
        interpret the DNT signal, our Services do not currently respond to
        browser DNT signals. For more information on DNT, see&nbsp;
        <a
          className={linkClass}
          href="https://allaboutdnt.com/"
          rel="nofollow noreferrer"
          target="_blank"
        >
          https://allaboutdnt.com/
        </a>
        .
      </p>

      <h2 className={titleClass}>
        Changes to This Cookie and Advertising Policy
      </h2>
      <p className={contentClass}>
        Every now and then, we will have to update this Cookie and Advertising
        Policy. The date at the top of this page indicates when it was last
        updated. You can always find the most updated version at this URL, and
        we will always post a notice on our website if we make significant
        changes.
      </p>
    </div>
  </Layout>
)

export default CookiePolicyPage
